import graphql__cd24e5b3a634b4ebae08 from "./__generated__/AppMongoRouterQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import { lazy, Suspense, useEffect } from 'react'
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay/hooks'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAppManagementContext } from 'scenes/appManagement/context'

import { AppMongoRouterQuery } from './__generated__/AppMongoRouterQuery.graphql'

const AppMongoConnectorScene = lazy(() => import('./AppMongoConnectorScene'))
const ConfigureMongoConnectorScene = lazy(() => import('./configure'))

const query = graphql__cd24e5b3a634b4ebae08

type Props = {
  queryRef: PreloadedQuery<AppMongoRouterQuery>
}

const AppMongoRouter = ({ queryRef }: Props) => {
  const {
    viewer: { appById: app },
  } = usePreloadedQuery(query, queryRef)

  const alreadyConfigured = !!app?.mongoConnector
  const canConfigure =
    !!app?.viewerCanManageMongoConnector && !alreadyConfigured

  return (
    <Routes>
      <Route path="/" element={<AppMongoConnectorScene />} />
      {canConfigure && (
        <Route path="/configure" element={<ConfigureMongoConnectorScene />} />
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default function AppMongoRouterContainer() {
  const [queryRef, loadQuery] = useQueryLoader<AppMongoRouterQuery>(query)

  const { appId } = useAppManagementContext()
  useEffect(() => {
    if (appId) {
      loadQuery({ appId }, { fetchPolicy: 'store-and-network' })
    }
  }, [loadQuery, appId])

  if (!queryRef) {
    return null
  }

  return (
    <Suspense>
      <AppMongoRouter queryRef={queryRef} />
    </Suspense>
  )
}
