import graphql__9fb3a125b096a2b6126b from "./__generated__/UserAppIdResolverQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import paths from 'paths'
import React, { Suspense, useEffect, useMemo } from 'react'
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import { UserAppIdResolverQuery } from './__generated__/UserAppIdResolverQuery.graphql'
import AppManagementLoader from './AppManagementLoader'

const query = graphql__9fb3a125b096a2b6126b

export default function UserAppIdResolverContainer() {
  const { appSlug, userId } = useParams<{
    appSlug: string
    userId?: string
  }>()

  const [queryRef, loadQuery] = useQueryLoader<UserAppIdResolverQuery>(query)

  useEffect(() => {
    loadQuery(
      {
        userId: userId || '',
        skipViewer: !!userId,
        skipUser: !userId,
      },
      {
        fetchPolicy: 'network-only',
      },
    )
  }, [userId, loadQuery])

  if (!queryRef || !appSlug) {
    return null
  }

  return (
    <UserAppIdResolver queryRef={queryRef} appSlug={appSlug} userId={userId} />
  )
}

type Props = {
  queryRef: PreloadedQuery<UserAppIdResolverQuery>
  appSlug: string
  userId?: string
}
// Resolves an app ID based on the current user's app slug.
const UserAppIdResolver = ({
  queryRef,
  appSlug,
  userId,
}: Props): React.ReactElement | null => {
  const {
    viewer: { me, user },
  } = usePreloadedQuery(query, queryRef)

  const targetUser = userId ? user : me
  const appId = useMemo(
    () => targetUser?.apps.find((app) => app.slug === appSlug)?.id,
    [appSlug, targetUser?.apps],
  )

  return !!appId ? (
    <Suspense fallback={null}>
      <AppManagementLoader appId={appId} />
    </Suspense>
  ) : (
    <Navigate
      to={`${paths.userApps({
        userId: targetUser?.id || '',
      })}?appNotFound=${appSlug}`}
      replace
    />
  )
}
