import graphql__e0b892864ab117b754fe from "./__generated__/RecentOrganizationAppsMenuItemQuery.graphql.ts";
import graphql__2134f57d6f8f418f131c from "./__generated__/RecentOrganizationAppsMenuItem_viewer.graphql.ts";import { Menu } from '@headlessui/react'
import { RawLink } from '@portal/components'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import paths from 'paths'
import { useEffect } from 'react'
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks'

import { RecentOrganizationAppsMenuItem_viewer$key } from './__generated__/RecentOrganizationAppsMenuItem_viewer.graphql'
import { RecentOrganizationAppsMenuItemQuery } from './__generated__/RecentOrganizationAppsMenuItemQuery.graphql'
import { RecentOrganizationAppsRefetchQuery } from './__generated__/RecentOrganizationAppsRefetchQuery.graphql'

const query = graphql__e0b892864ab117b754fe

const refetchableOrganizations = graphql__2134f57d6f8f418f131c

type Props = {
  /** True if the menu item is open */
  isOpen: boolean
}

// Menu item for accessing the organization apps that a user has access to.
export default function RecentOrganizationAppsMenuItem({ isOpen }: Props) {
  const { viewer } = useLazyLoadQuery<RecentOrganizationAppsMenuItemQuery>(
    query,
    {},
  )
  const [data, refetch] = useRefetchableFragment<
    RecentOrganizationAppsRefetchQuery,
    RecentOrganizationAppsMenuItem_viewer$key
  >(refetchableOrganizations, viewer)

  useEffect(() => {
    if (isOpen) {
      refetch({}, { fetchPolicy: 'store-and-network' })
    }
  }, [refetch, isOpen])

  const recentApps = data.me?.recentOrganizationApps || []
  if (!recentApps.length) {
    return null
  }

  return (
    <div className="pb-2">
      <span className="block px-4 pb-1 pt-2 text-sm text-foreground-subtle">
        Recently visited apps
      </span>
      {recentApps.map((recentApp) => (
        <Menu.Item key={recentApp.id}>
          {({ active }) => (
            <RawLink
              to={paths.app({
                organizationSlug:
                  (recentApp.tenant as { slug: string }).slug || '',
                appSlug: recentApp.slug,
              })}
              className={classNames(
                'group flex h-12 w-full items-center justify-between px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
                { 'bg-background-overlay': !active },
                { 'bg-background-overlay-hovered': active },
              )}
            >
              <span className="truncate whitespace-nowrap">
                {`${(recentApp.tenant as { name: string }).name} / ${
                  recentApp.name
                }`}
              </span>
            </RawLink>
          )}
        </Menu.Item>
      ))}
    </div>
  )
}
